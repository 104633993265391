:global(#app) {
  .action {
    margin-right: 20px;
  }

  .actions {
    align-items: center;
    display: flex;
    margin: 20px 20px;
  }
}

:global(#app) {
  .menuItem {
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    margin: 0;
    outline: 0;
    padding: 4px;
    text-align: left;
    width: 100%;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .menuItemText {
    display: inline-block;
    line-height: 32px;
    position: relative;
    width: calc(100% - 40px);
  }

  .menuItemTextActive:before {
    bottom: 2px;
    color: #798d99;
    content: "Г";
    font-size: 18px;
    font-weight: normal;
    line-height: 36px;
    position: absolute;
    right: 2px;
    text-align: center;
    transform: rotate(-135deg);
    width: 36px;
  }

  .user {
    display: inline-block;
    line-height: 32px;
    padding-right: 8px;
    width: 40px;
  }
}
